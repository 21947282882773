<template>
  <div id="cancelModal" class="modal fade" tabindex="-1">
    <div class="modal-dialog modal-md">
      <div class="modal-content">
        <div class="modal-header p-3 p-md-4">
          <h3 class="modal-title text-center w-100">
            Cancel Membership
          </h3>
          <button
            type="button"
            data-bs-dismiss="modal"
            class="btn-close"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body p-3 p-md-5">
          <p class="text-secondary fs-5 fw-nomal lh-base mb-4">
            We are very sorry to hear this. Hope to see you again soon.
          </p>

          <div class="mb-4">
            <div class="d-flex justify-content-between mb-2">
              <h6 class="fw-normal text-secondary mb-0">
                Subscription Date
              </h6>
              <div class="text-end">
                {{ subscriptionPlan.subscription_date }}
                <template v-if="isRookiePlan">
                  <br />
                  <span class="text-pink">
                    (Start 7 days free trial)
                  </span>
                </template>
              </div>
            </div>

            <div class="d-flex justify-content-between mb-2">
              <h6 class="fw-normal text-secondary mb-0">
                Effective Till
              </h6>
              <div class="text-end">
                {{ subscriptionPlan.effective_til }}
              </div>
            </div>

            <div class="d-flex justify-content-between">
              <h6 class="fw-normal text-secondary mb-0">
                Renewal On
              </h6>
              <div class="text-end">
                {{ subscriptionPlan.renewal_date }}
              </div>
            </div>
          </div>

          <div class="text-end border-top pt-4">
            <button
              @click="cancelPlan()"
              class="btn btn-lg btn-danger text-white"
            >
              Cancel Plan
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations } from 'vuex';

import { Modal } from 'bootstrap';

export default {
  name: 'cancelModal',

  props: ['subscriptionPlan', 'isRookiePlan'],
  data() {
    return {
      modal: {}
    };
  },

  methods: {
    ...mapMutations(['set_page_loading']),

    async cancelPlan() {
      this.set_page_loading(true);

      const res = await this.customAxios.post(
        `/subscription/${this.subscriptionPlan.paypal_subscription_id}/cancel`
      );

      this.$emit('onCanceled', res);

      this.modal.hide();

      this.set_page_loading(false);
    }
  },

  mounted() {
    this.modal = new Modal(document.getElementById('cancelModal'));
  }
};
</script>
