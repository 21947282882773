<template>
  <div v-if="isReady" id="plan" class="container">
    <h1 class="text-center mb-5 fw-bold">
      BinaryFlash Membership
    </h1>

    <div
      v-if="member.isLogin && status === 'rookie'"
      id="remind-section"
      class="section pb-5"
    >
      <div class="card">
        <div class="card p-2 p-md-4">
          <div class="card-body">
            <h3 class="title mb-4">HI, {{ member.name }}</h3>
            <p class="mb-0">
              You haven't subscribed to our service. Enjoy a 7-day free trial
              before joining the membership, which can be cancelled anytime
              during the subscription period.
            </p>
          </div>
        </div>
      </div>
    </div>

    <div id="details-section" class="section pb-5">
      <div class="card bg-main-3 p-2 p-md-4">
        <div class="card-body">
          <div class="d-flex align-items-center mb-3">
            <h2 class="fs-1 fw-bold mb-0">
              {{ $plan.currency }} {{ $plan.price }}
            </h2>
            <span class="fs-4 opacity-7 ms-3">
              / {{ $plan.intervals }} {{ $plan.interval_unit }}
            </span>

            <span
              v-if="status === 'under-processing'"
              class="d-none d-md-block ms-auto text-pink"
            >
              Waiting for PayPal’s Processing
            </span>
          </div>
          <div v-if="status === 'under-processing'" class="d-md-none text-pink">
            Waiting for PayPal’s Processing
          </div>

          <h3 v-if="status === 'rookie'" class="fw-bold">
            Start your
            <span class="text-color-highlight">
              7 days free
            </span>
            trial
          </h3>

          <hr class="my-5" />

          <div
            v-if="
              ['under-processing', 'subscriber', 'half-subscriber'].indexOf(
                status
              ) !== -1
            "
            class="mb-4"
          >
            <div class="d-flex justify-content-between mb-2">
              <h6 class="fw-normal opacity-7 mb-0">
                Subscription Date
              </h6>
              <div class="text-end">
                {{ subscriptionPlan.subscription_date }}
                <template v-if="$isRookiePlan">
                  <br />
                  <span class="text-pink">
                    (Start 7 days free trial)
                  </span>
                </template>
              </div>
            </div>

            <div class="d-flex justify-content-between mb-2">
              <h6 class="fw-normal opacity-7 mb-0">
                Effective Till
              </h6>
              <div class="text-end">
                {{ subscriptionPlan.effective_til }}
              </div>
            </div>

            <div class="d-flex justify-content-between mb-2">
              <h6 class="fw-normal opacity-7 mb-0">
                Renewal On
              </h6>
              <div class="text-end">
                {{ subscriptionPlan.renewal_date }}
              </div>
            </div>

            <hr class="my-5" />
          </div>

          <ul class="list mb-4">
            <li class="fs-5 mb-4">
              Instant delivery of latest trading signals
            </li>
            <li class="fs-5 mb-4">
              4 to 6 pairs of major currencies
            </li>
            <li class="fs-5 mb-4">
              8 to 12 signals in average per trading day
            </li>
          </ul>

          <div
            v-if="inProgress"
            id="paypal-button-container"
            class="my-5"
          ></div>
          <template v-else>
            <button
              v-if="status === 'rookie'"
              @click="action()"
              class="btn btn-main-1 text-white btn-lg w-100"
            >
              Try free for 7 days
            </button>
            <button
              v-if="status === 'non-subscriber'"
              @click="action()"
              class="btn btn-main-1 text-white btn-lg w-100"
            >
              Subscribe
            </button>
            <button
              v-if="status === 'subscriber'"
              data-bs-toggle="modal"
              data-bs-target="#cancelModal"
              class="btn btn-danger text-white btn-lg w-100"
            >
              Cancel Plan
            </button>
          </template>

          <hr class="my-5" />

          <p class="opacity-7 mb-0">
            Disclaimer: All information related to the signals generated by
            BinaryFlash are for reference only. They do not constitute actual
            advices to the trading of any financial instruments and their users
            should make their own judgements and decisions on how to interpret
            these signals. Enjoy a 7-day free trial before joining the
            membership, which can be cancelled anytime during the subscription
            period.
          </p>
        </div>
      </div>
    </div>

    <div id="faq-section" class="section pt-5">
      <h1 class="text-center mb-5 fw-bold">
        FAQ
      </h1>

      <FaqList id="faq-1" class="mb-3">
        <template v-slot:header>
          What kind of brokers should I use?
        </template>
        <template v-slot:content>
          Apart from choosing a reputed broker, you have to pick one that
          allows you to set the expiry time of the binary option.
        </template>
      </FaqList>

      <FaqList id="faq-2" class="mb-3">
        <template v-slot:header>
          How should I set the return ratio?
        </template>
        <template v-slot:content>
          You can set your return ratio in each trade to the maximumly available
          value provided by the broker. For most brokers it is 90%. It is
          because our signal generation engine has taken this parameter into
          consideration when maximizing your long-term profit and at the same
          time minimizing your loss by estimating the optimum position size for
          each trade. This latter figure, included in our signals for risk
          management, is a distinctive feature of BinaryFlash.
        </template>
      </FaqList>

      <FaqList id="faq-3" class="mb-3">
        <template v-slot:header>
          Why not more currency pairs?
        </template>
        <template v-slot:content>
          We focus on currency pairs that are most liquid in the market. Our
          research shows that high-quality trading signals are more likely to be
          generated for major currency pairs than exotics.
        </template>
      </FaqList>

      <FaqList id="faq-4" class="mb-3">
        <template v-slot:header>
          Why not more signals?
        </template>
        <template v-slot:content>
          Because of the unique properties of binary options, the winning rate
          of a successful trading strategy should have small fluctuations.
          Simply put, a strategy with an almost constant 65% of winning rate
          will be, in the long run, better than a strategy with winning rate
          swinging rapidly between 55% and 75%. Therefore we do not strive to
          generate excessive number of unreliable signals but signals with
          robust statistical characteristics.
        </template>
      </FaqList>

      <FaqList id="faq-5" class="mb-3">
        <template v-slot:header>
          Is BinaryFlash using technical analysis or human traders to generate
          signals?
        </template>
        <template v-slot:content>
          We employ neither technical analysis nor human traders to generate
          signals. Rather we apply our expert knowledge in mathematical finance,
          AI, and other scientific disciplines to build our signal generation
          engine, i.e., the approach commonly used by quants working in
          investment firms large or small.
        </template>
      </FaqList>

      <FaqList id="faq-6" class="mb-3">
        <template v-slot:header>
          Will BinaryFlash renew my membership automatically?
        </template>
        <template v-slot:content>
          Yes we will renew your 30-day membership automatically through the
          PayPal’s platform to save your renewal effort and to avoid
          discontinuation of your receiving signals. However, you can cancel
          your subscription anytime you wish and we will continue sending you
          signals till the end of your subscription period.
        </template>
      </FaqList>
    </div>

    <CancelModal
      @onCanceled="handleRes"
      :subscriptionPlan="subscriptionPlan"
      :isRookiePlan="$isRookiePlan"
    ></CancelModal>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex';

import FaqList from '@/components/plan/FaqList.vue';
import CancelModal from '@/components/plan/CancelModal.vue';

export default {
  name: 'Plan',
  components: {
    FaqList,
    CancelModal
  },

  data() {
    return {
      isReady: false,
      inProgress: false,

      status: 'rookie',

      planList: [],
      subscriptionPlan: {}
    };
  },
  computed: {
    ...mapState(['member', 'modals']),

    $plan() {
      const rookiePlan =
        this.planList.find(plan => {
          return plan.code === 'basic_plan_for_rookie';
        }) || {};

      const generalPlan =
        this.planList.find(plan => {
          return plan.code === 'basic_plan_for_general';
        }) || {};

      if (this.status === 'rookie') {
        return rookiePlan;
      } else {
        return generalPlan;
      }
    },

    $isRookiePlan() {
      const rookiePlan =
        this.planList.find(plan => {
          return plan.code === 'basic_plan_for_rookie';
        }) || {};

      return this.subscriptionPlan.paypal_plan_id === rookiePlan.paypal_plan_id;
    }
  },

  watch: {
    'member.isLogin': async function(val) {
      if (!this.isReady || !val) {
        return;
      }

      await this.fetchSubscription();
    }
  },

  methods: {
    ...mapMutations(['set_page_loading']),

    async fetchSubscription() {
      this.isReady = false;

      const res = await this.customAxios.get('/subscription');

      this.handleRes(res);

      this.isReady = true;
    },

    async action() {
      if (!this.member.isLogin) {
        this.modals.login.show();

        return;
      }

      if (!this.member.email_verified) {
        this.modals.emailVerification.show();

        return;
      }

      const res = await this.customAxios.post('/subscription/custom_id');

      this.inProgress = true;

      await this.$nextTick();

      paypal
        .Buttons({
          createSubscription: (data, actions) => {
            return actions.subscription.create({
              plan_id: this.$plan.paypal_plan_id,
              custom_id: res.data.result.custom_id
            });
          },

          onApprove: async (data, actions) => {
            /*
              data: {
                orderID: '',
                paymentID: null,
                billingToken: '',
                subscriptionID: '',
                facilitatorAccessToken: ''
              };
            */

            const subscriptionRes = await this.customAxios.post(
              `/subscription/${data.subscriptionID}/register`
            );

            this.handleRes(subscriptionRes);

            if (!Object.keys(this.subscriptionPlan).length) {
              swal({
                title: 'failure',
                icon: 'error',
                button: 'OK'
              });

              return;
            }

            const checkStatus = setInterval(async () => {
              if (this.status !== 'under-processing') {
                return;
              }

              await this.fetchSubscription();
            }, 10 * 1000);

            setTimeout(() => {
              clearInterval(checkStatus);
            }, 3 * 60 * 1000);

            this.inProgress = false;
          }
        })
        .render('#paypal-button-container');
    },

    handleRes(res) {
      const { member_status, subscription } = res.data.result;

      this.status = member_status;
      this.subscriptionPlan = subscription || {};
    }
  },

  async mounted() {
    this.set_page_loading(true);

    const res = await this.customAxios.get('/subscription/plans');

    this.planList = res.data.result.billing_plans;

    if (this.member.isLogin) {
      await this.fetchSubscription();
    }

    this.isReady = true;

    this.set_page_loading(false);
  }
};
</script>

<style lang="scss" scoped>
@import '@/scss/all.scss';

.section {
  max-width: 40rem;
  margin: auto;
}

.card {
  box-shadow: 10px 10px 50px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
}

#details-section {
  .list {
    list-style: none;
    padding-left: 0;

    > li {
      display: flex;
      align-items: center;

      &::before {
        content: '';
        display: inline-block;
        height: 2rem;
        width: 2rem;
        background-image: url(~@/assets/img/plan/check-circle.png);
        background-size: cover;
        margin-right: 1rem;
        flex-shrink: 0;
      }
    }
  }
}

#remind-section {
  .card {
    background-color: rgba(255, 81, 164, 0.6);
  }

  .title {
    background-image: url(~@/assets/img/plan/chat-dots.png);
    background-size: 2rem;
    background-repeat: no-repeat;
    background-position: left center;
    padding-left: 3rem;
  }
}
</style>
